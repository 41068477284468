define("discourse/plugins/discourse-6dc-tipper/discourse/controllers/preferences-wallet", ["exports", "@ember/controller", "discourse-common/config/environment", "discourse-common/utils/decorators", "@ember/object", "discourse/lib/ajax-error", "@ember/service", "discourse/lib/show-modal", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/transfer-modal", "discourse/lib/ajax", "discourse/plugins/discourse-6dc-tipper/discourse/lib/web3modal"], function (_exports, _controller, _environment, _decorators, _object, _ajaxError, _service, _showModal, _transferModal, _ajax, _web3modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // import Web3Utils from "../lib/web3utils";
  let PreferencesWalletController = _exports.default = (_dec = (0, _object.computed)('walletAddress'), _dec2 = (0, _object.computed)('privateWalletAddress'), (_class = class PreferencesWalletController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      this.set("walletTokenName", "...");
      this.set("walletAddress", "0x0000000000000000");
      this.set("totalBalance", 0);
      this.set("walletBalance", "...");
      this.set("privateWalletAddress", "0x0000000000000000");
      this.set("privateWalletBalance", "...");
      this.set("walletTransactions", []);
      this.fetchWalletData();
      this.fetchWalletTransactions();
    }
    async fetchWalletData() {
      try {
        let {
          status,
          data
        } = await (0, _ajax.ajax)('/discourse-6dc-tipper/fw');
        if (status) {
          let provider = _web3modal.default.create();
          await provider.providerInit({});
          data.forEach(async wallet => {
            const walletInfo = await provider.getBalanceForWallet(this.siteSettings.network_rpc_url, wallet.address, this.siteSettings.erc_20_contract.length ? {
              name: this.siteSettings.currency,
              address: this.siteSettings.erc_20_contract
            } : {
              name: this.siteSettings.currency,
              address: ''
            });
            if (wallet.is_private) {
              this.set("privateWalletAddress", wallet.address);
              this.set("privateWalletBalance", walletInfo.balance);
            } else {
              this.set("walletAddress", wallet.address);
              this.set("walletBalance", walletInfo.balance);
            }
            this.set("totalBalance", parseFloat(this.get("totalBalance")) + parseFloat(walletInfo.balance));
            this.set("walletTokenName", walletInfo.token);
            console.log(wallet.address, "walletInfo:", walletInfo);
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
    splitAndAddEllipsis(str, len) {
      const start = str.slice(0, len);
      const end = str.slice(-len);
      return start + '...' + end;
    }
    async fetchWalletTransactions() {
      try {
        let {
          status,
          data
        } = await (0, _ajax.ajax)('/discourse-6dc-tipper/transactions');
        if (status) {
          const updatedData = data.map(item => {
            return {
              ...item,
              tx_hash: this.splitAndAddEllipsis(item.tx_hash, 23),
              created_at: moment(item.created_at).fromNow() // change the format as needed
            };
          });
          this.set("walletTransactions", updatedData);
        }
      } catch (err) {
        console.error(err);
      }
    }
    get userWallet() {
      return this.walletAddress !== "0x0000000000000000" ? this.walletAddress : false;
    }
    get privateUserWallet() {
      return this.privateWalletAddress !== "0x0000000000000000" ? this.privateWalletAddress : false;
    }
    async onSendFunds() {
      let data = await (0, _ajax.ajax)('/discourse-6dc-tipper/st').catch(e => {
        return console.error(e);
      });
      console.log(data);
    }
    onTransferToggle() {
      this.modal.show(_transferModal.default);
    }
    async generateWallet() {
      let data = await (0, _ajax.ajax)('/discourse-6dc-tipper/gw').catch(e => {
        return console.error(e);
      });
      console.log(data);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "userWallet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "userWallet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "privateUserWallet", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "privateUserWallet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSendFunds", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSendFunds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTransferToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTransferToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateWallet", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateWallet"), _class.prototype)), _class));
});