define("discourse/plugins/discourse-6dc-tipper/discourse/components/user-tip-display", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/tip-modal", "@ember/template-factory"], function (_exports, _component, _service, _object, _tipModal, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
  <DButton
      @action={{action "toggleModal"}}
      @class={{this.buttonClass}}
      @label="tip_button_title"
      @icon="hand-holding-usd"
  />
  {{/if}}
  */
  {
    "id": "EjxE7TY6",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[8,[39,1],null,[[\"@action\",\"@class\",\"@label\",\"@icon\"],[[28,[37,2],[[30,0],\"toggleModal\"],null],[30,0,[\"buttonClass\"]],\"tip_button_title\",\"hand-holding-usd\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/components/user-tip-display.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class _class extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _defineProperty(this, "tagName", "");
      _defineProperty(this, "shouldDisplay", true);
      _defineProperty(this, "location", '');
      _defineProperty(this, "buttonClass", 'btn-primary user-card-chat-btn btn-icon-text');
    }
    init() {
      super.init(...arguments);
      this.show(this.getTargetUser());
    }
    show(user) {
      if (this.currentUser) {
        if (user.id === -1 || user.id === this.currentUser.id) this.set("shouldDisplay", false);
      } else {
        this.set("shouldDisplay", false);
      }
    }
    getTargetUser() {
      let user;
      if (!!!this.currentUser) return {
        id: -1,
        username: "system"
      };
      switch (this.location) {
        case "profile-view":
          user = {
            id: this.model.id,
            username: this.model.username
          };
          this.set("buttonClass", "btn-default");
          break;
        case "profile-popup":
          user = {
            id: this.parentView.user.id,
            username: this.parentView.user.username
          };
          break;
        default:
          user = {
            id: this.currentUser.id,
            username: this.currentUser.username
          };
          break;
      }
      return user;
    }
    async toggleModal() {
      this.modal.show(_tipModal.default, {
        model: {
          user: this.getTargetUser()
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal"), _class.prototype)), _class));
});